import gql from 'graphql-tag'


export const LIST_ATTACHMENT_INVENTORY = (templateType) => gql`query LIST_ATTACHMENT_INVENTORY ($docType: String!, $inventoryId: Int!) {
  items: listDoc${templateType}AttachmentInventory (docType: $docType, inventoryId: $inventoryId) {
    id filename thumbnail
  }
}`

export const DETAIL_ATTACHMENT_INVENTORY = (templateType) => gql`query DETAIL_ATTACHMENT_INVENTORY ($docType: String!, $attachmentId: Int!) {
  url: detailDoc${templateType}AttachmentInventory (docType: $docType, attachmentId: $attachmentId)
}`
