import gql from 'graphql-tag'


export const SETUP_PROCEDURE_CONTROL = (templateType) => gql`mutation SETUP_PROCEDURE_CONTROL ($docType: String!, $docId: Int!) {
  setupProcedure: setupDoc${templateType}ProcedureControl (docType: $docType, docId: $docId)
}`

export const SETUP_PROCEDURE_QUATITY = (templateType) => gql`mutation SETUP_PROCEDURE_QUATITY ($docType: String!, $docId: Int!) {
  setupProcedure: setupDoc${templateType}ProcedureQuality (docType: $docType, docId: $docId)
}`
