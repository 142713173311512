import gql from 'graphql-tag'
import { capitalize } from '@/libs/form'

const detailResponse = `
  id type code name
  children {
    id code name description cron
    formType choices {text value}
    targetValue actualValue
    minValue maxValue
    inventoryId inventory {id type code name}
    deviceId device {id type code name}
    children {
      id actualValue remark
      createdAt createdBy createdUser {id name}
    }
  }
`

export const LIST_MEASUREMENT = (templateType, tag) => {
  let x = ''
  if (tag === 'control') x = 'Control'
  else if (tag === 'quality') x = 'Quality'

  return gql`query LIST_MEASUREMENT ($docType: String!, $docId: Int!) {
    items: listDoc${templateType}${x} (docType: $docType, docId: $docId) {${detailResponse}}
  }`
}

export const DETAIL_MEASUREMENT = (templateType, tag) => gql`query DETAIL_MEASUREMENT ($docType: String!, $docId: Int!, $measurementId: Int!) {
  item: detailDoc${templateType}${capitalize(tag)} (docType: $docType, docId: $docId, measurementId: $measurementId) {
    id code name description cron
    formType choices {text value}
    targetValue actualValue
    minValue maxValue
    inventoryId inventory {id type code name}
    deviceId device {id type code name}
    children {
      id actualValue remark
      createdAt createdBy createdUser {id name}
    }
  }
}`

export const LIST_MEASUREMENT_DEVICE = (templateType, tag) => gql`query LIST_MEASUREMENT_DEVICE ($docType: String!, $docId: Int!, $measurementId: Int!, $bucket: String!, $startDate: DateTime!, $endDate: DateTime!) {
  children: listDoc${templateType}${capitalize(tag)}Device (docType: $docType, docId: $docId, measurementId: $measurementId, bucket: $bucket, startDate: $startDate, endDate: $endDate) {
    time actualValue
  }
}`

export const CREATE_MEASUREMENT = (templateType, tag) => gql`mutation CREATE_MEASUREMENT ($docType: String!, $docId: Int!, $measurementId: Int!, $input: Doc${templateType}MeasurementInput!) {
  item: createDoc${templateType}${capitalize(tag)} (docType: $docType, docId: $docId, measurementId: $measurementId, input: $input) {${detailResponse}}
}`
