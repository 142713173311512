import gql from 'graphql-tag'


const childResponse = `
  id type name parentId
  qty price totalPrice
  unitId unit {id name}
  contactId contact {id type code name}
  stocks {
    id qty docId parentId orderId isPrinted
    inventoryId inventory {
      id code name
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {
    id type name parentId
    qty minQty maxQty
    unitId unit {id name}
    inventory {
      id code name packing
      junctionChildren {
        id code name packing
      }
    }
    stocks {
      id qty docId parentId orderId isPrinted
      inventoryId inventory {
        id code name
        unitId unit {id name}
      }
      createdAt createdBy createdUser {id name}
      approvedAt approvedBy approvedUser {id name}
    }
    ledgers {
      id docId stockId orderId amount
      accountId account {id code name}
      createdAt createdBy createdUser {id name}
      approvedAt approvedBy approvedUser {id name}
    }
    children {
      id type name parentId
      qty price totalPrice
      unitId unit {id name}
      contactId contact {id type code name}
      stocks {
        id qty docId parentId orderId isPrinted
        inventoryId inventory {
          id code name
          unitId unit {id name}
        }
        createdAt createdBy createdUser {id name}
        approvedAt approvedBy approvedUser {id name}
      }
      ledgers {
        id docId stockId orderId amount
        accountId account {id code name}
        createdAt createdBy createdUser {id name}
        approvedAt approvedBy approvedUser {id name}
      }
    }
  }
}`

export const CREATE_PRODUCT_ORDER = (templateType) => gql`mutation CREATE_PRODUCT_ORDER ($docType: String!, $orderId: Int!, $inventoryId: Int!, $qty: Float!, $deviceId: Int, $employeeId: Int) {
  createOrder: createDoc${templateType}ProductOrder (docType: $docType, orderId: $orderId, inventoryId: $inventoryId, qty: $qty, deviceId: $deviceId, employeeId: $employeeId) {${childResponse}}
}`

export const DESTROY_PRODUCT_ORDER = (templateType) => gql`mutation DESTROY_PRODUCT_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}ProductOrder (docType: $docType, orderId: $orderId) {id}
}`

export const APPROVE_PRODUCT_ORDER = (templateType) => gql`mutation APPROVE_PRODUCT_ORDER ($docType: String!, $orderId: Int!) {
  approveOrder: approveDoc${templateType}ProductOrder (docType: $docType, orderId: $orderId) {id}
}`

export const APPROVE_PRODUCT_ORDER_BY_STOCK_ID = (templateType) => gql`mutation APPROVE_ORDER_BY_STOCK_ID ($docType: String!, $stockId: Int!) {
  approveOrder: approveDoc${templateType}ProductOrderByStockId (docType: $docType, stockId: $stockId) {id}
}`

export const ROLLBACK_PRODUCT_ORDER = (templateType) => gql`mutation ROLLBACK_PRODUCT_ORDER ($docType: String!, $orderId: Int!) {
  rollbackOrder: rollbackDoc${templateType}ProductOrder (docType: $docType, orderId: $orderId) {id}
}`

export const CREATE_MATERIAL_ORDER = (templateType) => gql`mutation CREATE_MATERIAL_ORDER ($docType: String!, $orderId: Int!, $inventoryId: Int!, $qty: Float!, $stockId: Int, $employeeId: Int) {
  createOrder: createDoc${templateType}MaterialOrder (docType: $docType, orderId: $orderId, inventoryId: $inventoryId, qty: $qty, stockId: $stockId, employeeId: $employeeId) {${childResponse}}
}`

export const DESTROY_MATERIAL_ORDER = (templateType) => gql`mutation DESTROY_MATERIAL_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}MaterialOrder (docType: $docType, orderId: $orderId) {id}
}`

export const APPROVE_MATERIAL_ORDER = (templateType) => gql`mutation APPROVE_MATERIAL_ORDER ($docType: String!, $orderId: Int!) {
  approveOrder: approveDoc${templateType}MaterialOrder (docType: $docType, orderId: $orderId) {id}
}`

export const ROLLBACK_MATERIAL_ORDER = (templateType) => gql`mutation ROLLBACK_MATERIAL_ORDER ($docType: String!, $orderId: Int!) {
  rollbackOrder: rollbackDoc${templateType}MaterialOrder (docType: $docType, orderId: $orderId) {id}
}`

export const CREATE_SCRAP_ORDER = (templateType) => gql`mutation CREATE_SCRAP_ORDER ($docType: String!, $orderId: Int!, $inventoryId: Int!, $qty: Float!, $deviceId: Int, $employeeId: Int) {
  createOrder: createDoc${templateType}ScrapOrder (docType: $docType, orderId: $orderId, inventoryId: $inventoryId, qty: $qty, deviceId: $deviceId, employeeId: $employeeId) {${childResponse}}
}`

export const DESTROY_SCRAP_ORDER = (templateType) => gql`mutation DESTROY_SCRAP_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}ScrapOrder (docType: $docType, orderId: $orderId) {id}
}`

export const APPROVE_SCRAP_ORDER = (templateType) => gql`mutation APPROVE_SCRAP_ORDER ($docType: String!, $orderId: Int!) {
  approveOrder: approveDoc${templateType}ScrapOrder (docType: $docType, orderId: $orderId) {id}
}`

export const ROLLBACK_SCRAP_ORDER = (templateType) => gql`mutation ROLLBACK_SCRAP_ORDER ($docType: String!, $orderId: Int!) {
  rollbackOrder: rollbackDoc${templateType}ScrapOrder (docType: $docType, orderId: $orderId) {id}
}`


export const WATCH_ORDER_CREATED = (templateType) => gql`subscription WATCH_ORDER_CREATED ($docType: String!, $docId: Int!) {
  orderCreated: watchDoc${templateType}OrderCreated (docType: $docType, docId: $docId) {${childResponse}}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {${childResponse}}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {id}
}`


export const LIST_ORDER_INVENTORY = (templateType) => gql`query LIST_ORDER_INVENTORY ($docType: String!, $docId: Int!) {
  inventories: listDoc${templateType}OrderInventory (docType: $docType, docId: $docId) {
    id type code name
  }
}`

export const LIST_ORDER_EMPLOYEE = (templateType) => gql`query LIST_ORDER_EMPLOYEE ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}OrderEmployee (docType: $docType, q: $q) {
    id type code name branch
  }
}`
