import gql from 'graphql-tag'


export const LIST_REPORT_STOCK_IN = (templateType) => gql`query LIST_REPORT_STOCK_IN ($docType: String!, $startDate: Date!, $endDate: Date!, $search: String) {
  rows: listDoc${templateType}ReportStockIn (docType: $docType, startDate: $startDate, endDate: $endDate, search: $search) {
    id code name total unit
  }
}`

export const LIST_REPORT_STOCK_OUT = (templateType) => gql`query LIST_REPORT_STOCK_OUT ($docType: String!, $startDate: Date!, $endDate: Date!, $search: String) {
  rows: listDoc${templateType}ReportStockOut (docType: $docType, startDate: $startDate, endDate: $endDate, search: $search) {
    id code name total unit
  }
}`

export const LIST_REPORT_MACHINE = (templateType) => gql`query LIST_REPORT_MACHINE ($docType: String!, $startDate: Date!, $endDate: Date!, $machineSearch: String, $inventorySearch: String, $docSearch: String) {
  rows: listDoc${templateType}ReportMachine (docType: $docType, startDate: $startDate, endDate: $endDate, machineSearch: $machineSearch, inventorySearch: $inventorySearch, docSearch: $docSearch) {
    id type code name workingHour
    inventories {
      id type code name total
      productionRates workingHour
    }
    docRepairs {
      id type code name remark
    }
    machineRepairs {
      id type code name remark
    }
  }
}`
