import gql from 'graphql-tag'


export const LIST_LABEL_CONTACT_COMPANY_DEVICE = (templateType) => gql`query LIST_LABEL_CONTACT_COMPANY_DEVICE ($docType: String!) {
  devices: listDoc${templateType}LabelContactCompanyDevice (docType: $docType) {id code name}
}`

export const PRINT_LABEL_CONTACT_COMPANY = (templateType) => gql`mutation PRINT_LABEL_CONTACT_COMPANY ($docType: String!, $deviceId: Int! $qty: Int!) {
  print: printDoc${templateType}LabelContactCompany (docType: $docType, deviceId: $deviceId, qty: $qty)
}`


export const LIST_LABEL_INVENTORY_BARCODE_DETAIL_DEVICE = (templateType) => gql`query LIST_LABEL_INVENTORY_BARCODE_DETAIL_DEVICE ($docType: String!) {
  devices: listDoc${templateType}LabelInventoryBarcodeDetailDevice (docType: $docType) {id code name}
}`

export const PRINT_LABEL_INVENTORY_BARCODE_DETAIL = (templateType) => gql`mutation PRINT_LABEL_INVENTORY_BARCODE_DETAIL ($docType: String!, $deviceId: Int! $inventoryId: Int! $qty: Int!) {
  print: printDoc${templateType}LabelInventoryBarcodeDetail (docType: $docType, deviceId: $deviceId, inventoryId: $inventoryId, qty: $qty)
}`


export const LIST_LABEL_INVENTORY_CAUTION_DEVICE = (templateType) => gql`query LIST_LABEL_INVENTORY_CAUTION_DEVICE ($docType: String!) {
  devices: listDoc${templateType}LabelInventoryCautionDevice (docType: $docType) {id code name}
}`

export const PRINT_LABEL_INVENTORY_CAUTION = (templateType) => gql`mutation PRINT_LABEL_INVENTORY_CAUTION ($docType: String!, $deviceId: Int! $qty: Int!) {
  print: printDoc${templateType}LabelInventoryCaution (docType: $docType, deviceId: $deviceId, qty: $qty)
}`


export const LIST_LABEL_INVENTORY_STOCK_DEVICE = (templateType) => gql`query LIST_LABEL_INVENTORY_STOCK_DEVICE ($docType: String!) {
  devices: listDoc${templateType}LabelInventoryStockDevice (docType: $docType) {
    id code name
  }
}`

export const PRINT_LABEL_INVENTORY_STOCK = (templateType) => gql`mutation PRINT_LABEL_INVENTORY_STOCK ($docType: String!, $deviceId: Int!, $stockId: Int!, $qty: Int!) {
  print: printDoc${templateType}LabelInventoryStock (docType: $docType, deviceId: $deviceId, stockId: $stockId, qty: $qty)
}`
