import gql from 'graphql-tag'


const docConfigResponse = `
  id type docType name
  inventoryId inventory {
    id type code name unitId
    unit {id name}
  }
  deviceId device {
    id type code name
  }
  contactId contact {
    id type code name branch
  }
  paperId paper {id type code name}
  actionPlanId actionPlan {id name}
  riskAssessmentId riskAssessment {id name}
  targetGraphId targetGraph {id name}
  docCategoryId docCategory {id name}
`

export const LIST_DOC_CONFIG_MENU = (templateType) => gql`query LIST_DOC_CONFIG_MENU ($docType: String!) {
  menus: listDoc${templateType}DocConfigMenu (docType: $docType)
}`

export const LIST_DOC_CONFIG = (templateType) => gql`query LIST_DOC_CONFIG ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DocConfig (docType: $docType, q: $q) {${docConfigResponse}}
}`

export const CREATE_DOC_CONFIG = (templateType) => gql`mutation CREATE_DOC_CONFIG ($docType: String!, $docConfigId: Int, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfig (docType: $docType, docConfigId: $docConfigId, input: $input) {${docConfigResponse}}
}`

export const CREATE_DOC_CONFIG_CHILDREN = (templateType) => gql`mutation CREATE_DOC_CONFIG_CHILDREN ($docType: String!, $input: DocConfigInput!) {
  item: createDoc${templateType}DocConfig (docType: $docType, input: $input) {
    id type docType parentId
  }
}`

export const DESTROY_DOC_CONFIG = (templateType) => gql`mutation DESTROY_DOC_CONFIG ($docType: String!, $docConfigId: Int!) {
  item: destroyDoc${templateType}DocConfig (docType: $docType, docConfigId: $docConfigId) {id}
}`

export const LIST_DOC_CONFIG_IMPORT_INVENTORY = (templateType) => gql`query LIST_DOC_CONFIG_IMPORT_INVENTORY ($docType: String!, $input: [Doc${templateType}DocConfigImportInventoryInput]!) {
  result: listDoc${templateType}DocConfigImportInventory (docType: $docType, input: $input) {
    errors {code}
    adds {id type code name}
  }
}`
