import gql from 'graphql-tag'


export const LIST_BARCODE_SCANNER = (templateType) => gql`query LIST_BARCODE_SCANNER ($docType: String!) {
  devices: listDoc${templateType}BarcodeScanner (docType: $docType) {
    id type code name
  }
}`

export const SETUP_BARCODE_SCANNER = (templateType) => gql`mutation SETUP_BARCODE_SCANNER ($docType: String!, $deviceId: Int!) {
  uid: setupDoc${templateType}BarcodeScanner (docType: $docType, deviceId: $deviceId)
}`

export const WATCH_BARCODE_SCANNER_SCANNED = (templateType) => gql`subscription WATCH_BARCODE_SCANNER_SCANNED ($docType: String!, $uid: String!) {
  scanned: watchDoc${templateType}BarcodeScannerScanned (docType: $docType, uid: $uid) {
    uid barcode
  }
}`
